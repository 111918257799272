
export const CompanyData={
    short_name:"Valletech",
    name: "Valletech AB",
    address: "Blåmesvägen 26, 186-47 Vallentuna, Stockhoms Län, Sweden",
    short_address: "Blamesv. 26, 186-47 Sweden",
    email: "info@valletech.eu",
    support_email: "support@valletech.eu",
    phone: "+46 723 199 055",
    org_number: "559067-3694",
    VAT: "SE559067369401",
    support_portal: "https://support.valletech.eu",
    linkedin_url: "https://www.linkedin.com/company/valletech-ab"
};