import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, colors } from '@material-ui/core';

import { SectionHeader } from 'components/molecules';
import { HeroBackground } from 'components/organisms';

const useStyles = makeStyles(theme => ({
    hero: {
        padding: theme.spacing(3, 2, 30, 2),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(3, 6, 30, 6),
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(5, 8, 30, 8),
        },
    },
    noPadding: {
        padding: 0,
    },
}));

export const Hero = ({themeMode,className,...data}) => {
    const classes = useStyles();

    return (
        <div className={className} data-aos="fade-up">
            <HeroBackground
                backgroundImg={themeMode == 'light' ? data.banner_image : data.banner_image_dk}
                backgroundColor={themeMode == 'light' ? "white" :"black"  }
                backgroundPosition="bottom center"
                contentSectionClassName={classes.noPadding}
                className={classes.hero}
                disbaleCoverOpacity
            >
                <SectionHeader
                    title={data.title}
                    subtitle={data.subtitle}
                    ctaGroup={[
                        <Button color="primary" variant="contained" size="large" href={data.href}>
                            {data.action}
                        </Button>,
                    ]}
                    fadeUp
                    titleVariant="h3"
                />
            </HeroBackground>
        </div>
    );
};

Hero.propTypes = {
    /**
     * External classes
     */
    className: PropTypes.string,
};
