
const images={
    light_logo: require('assets/images/logo/logohc.svg'),
    dark_logo: require('assets/images/logo/logohi.svg'),
    develop_banner: require('assets/images/develop_banner.png'),
    develop_banner_dk: require('assets/images/develop_banner.png'),

    // Stock photos
    engage_stakeholders: require('assets/images/engage_stakeholders.svg'),
    build_mockups: require('assets/images/build_mockups.svg'),
    build_proto: require('assets/images/build_proto.svg'),

    // Tech Stacks
    java_stack: require('assets/images/stacks/java.svg'),
    nodejs_stack: require('assets/images/stacks/nodejs.svg'),
    docker_stack: require('assets/images/stacks/docker.svg'),
    kubernetes_stack: require('assets/images/stacks/kubernetes.svg'),
    rust_stack: require('assets/images/stacks/rust.svg'),
    substrate_stack: require('assets/images/stacks/substrate.svg'),
    gitlab_stack: require('assets/images/stacks/gitlab.svg'),
    ansible_stack: require('assets/images/stacks/ansible.svg'),
    cloudstack: require('assets/images/stacks/cloudstack.svg'),


    // References
    reuters: require("assets/images/references/reuters.svg"),
    tibco: require("assets/images/references/tibco.svg"),
    credit_swisse: require("assets/images/references/credit_suisse.svg"),
    vodafone: require("assets/images/references/vodafone.svg"),
    celpax: require("assets/images/references/celpax.svg"),

    // images migrated from the theme
    contact_phone_icon: require('assets/images/theme/contact-icon-phone.svg'),
    contact_mail_icon: require('assets/images/theme/contact-icon-mail.svg'),
    contact_pin_icon: require('assets/images/theme/contact-icon-address.svg'),

    check_icon: require('assets/images/theme/check-icon-yellow.svg')




};


const icons={

};

export {images,icons};