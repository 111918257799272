import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {useMediaQuery} from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { ZammadContactForm } from 'components/organisms';

const useStyles = makeStyles(theme => ({
    form: {
        maxWidth: 550,
        margin: `0 auto`,
        '& .MuiTextField-root': {
            background: theme.palette.background.paper,
        },
        '& .MuiOutlinedInput-input': {
            background: theme.palette.background.paper,
        },
    },
    inputTitle: {
        fontWeight: 700,
        marginBottom: theme.spacing(1),
    },
}));

export const ContactForm =  ({ className, ...props }) => {
    // Translation temporarily disabled
    //const {t} = useTranslation();
    const t = (txt)=>txt;
    const classes = useStyles();

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const submitForm= () =>{
        console.debug("Submitting form");
    };

    return (
        <div className={className}>
            <SectionHeader
                title={props.title}
                subtitle={props.subtitle}
                subtitleProps={{
                    variant: 'body1',
                    color: 'textPrimary',
                }}
                data-aos="fade-up"
                align={isMd ? 'center' : 'left'}
            />
            <ZammadContactForm
                url={props.url}
                ticketTitle={props.ticketTitle}
            />
        </div>
    );
};

ContactForm.propTypes = {
    /**
     * External classes
     */
    className: PropTypes.string,
};

