import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, TextField } from '@material-ui/core';
import validate from 'validate.js';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  content: {
    flex: '0 0 100%',
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      flex: '0 0 50%',
      maxWidth: '50%',
    },
  },  
}));

const schema = {
  'mauticform[email]': {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 300,
    },
  }
};

const ContactForm = () => {
  const classes = useStyles();

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const helperText = (field,label) =>{
    let err=formState.errors[field][0];
    let errw=err.split(' ');
    errw[0]=label;
    return errw.join(' ');
  }


  return (
    <div className={classes.root}>
      <form 
        autocomplete="false" 
        role="form" 
        method="post" 
        action="https://get.privaz.io/form/submit?formId=1" 
        enctype="multipart/form-data">

        <input type="hidden" name="mauticform[formId]" id="mauticform_getstarted_id" value="1"/>
        <input type="hidden" name="mauticform[return]" id="mauticform_getstarted_return" value=""/>
        <input type="hidden" name="mauticform[formName]" id="mauticform_getstarted_name" value="getstarted"/>

        <Grid container spacing={2}>          
          <Grid item xs={12}>
            <TextField
              placeholder="E-mail"
              label="E-mail *"
              variant="outlined"
              size="medium"
              name="mauticform[email]"
              fullWidth
              helperText={hasError('mauticform[email]') ? helperText('mauticform[email]','E-mail') : null}
              error={hasError('mauticform[email]')}
              onChange={handleChange}
              type="email"
              value={formState.values['mauticform[email]'] || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              &nbsp;
            </Typography>
            <Button
              size="large"
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
              disabled={!formState.isValid}
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ContactForm;

