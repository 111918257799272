import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, colors } from '@material-ui/core';
import { SectionHeader, IconAlternate } from 'components/molecules';
import { CardBase, DescriptionListIcon } from 'components/organisms';

export const Features = ({className,features,...props}) => {

  return (
    <div className={className} data-aos="fade-up">
      <SectionHeader title={props.title} fadeUp />
      <Grid container spacing={4}>
        {features.map((item, index) => (
          <Grid
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={12}
            sm={6}
            md={4}
            data-aos="fade-up"
          >
            <CardBase liftUp variant="outlined" withShadow>
              <DescriptionListIcon
                icon={
                  <IconAlternate
                    fontIconClass={item.icon}
                    color={colors.indigo}
                  />
                }
                title={item.title}
                subtitle={item.description}
              />
            </CardBase>
          </Grid>
        ))}
        <Grid item container xs={12} justifyContent="center">
          <Button variant="contained" size="large" color="primary" href={props.href}>
            {props.action}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

Features.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  features: PropTypes.array.isRequired,
};

