/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'gatsby-plugin-react-i18next';
import {useStaticQuery, graphql} from 'gatsby';

export const SEO= function ({description, meta, title}) {
    const {site} = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            imageUrl
          }
        }
      }
    `
    );

    const metaDescription = description || site.siteMetadata.description;


    return (
        <Helmet
            title={title}
            meta={[
                {
                    name: `description`,
                    content: metaDescription
                },
                {
                    property: `og:title`,
                    content: title
                },
                {
                    property: `og:description`,
                    content: metaDescription
                },
                {
                    property: `og:locale`,
                    content: `en_US`
                },
                {
                    property: `og:type`,
                    content: `website`
                },
                {
                    name: `twitter:card`,
                    content: `summary`
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author
                },
                {
                    name: `twitter:title`,
                    content: title
                },
                {
                    name: `twitter:description`,
                    content: metaDescription
                },
                {   name: `og:url`,
                    content: site.siteMetadata.siteUrl
                },
                {   name: 'og:image',
                    content: site.siteMetadata.imageUrl
                },
                {
                    name: `robots`,
                    content: "max-snippet:-1, max-image-preview:large, max-video-preview:-1"
                }
            ].concat(meta)}
        />
    );
}

SEO.defaultProps = {
    meta: [],
    description: ``
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired
};

