import {images} from "../components/MediaLib";

//const {t} = useTranslation();
const t = (txt)=>txt;

export const InnovationWorkflow = [
    {
        title: t('Engage Stakeholders'),
        description: t('What is the problem that needs solving? What is the state of the art and latest research? What are the viable Solutions? What use cases could be monetized? '),
        illustration: images.engage_stakeholders,
        href: "/contact",
    },
    {
        title: t('Mockup your ideas'),
        description: t('Create cardboard mock-ups, wireframes for the purpose of discussing and further engaging stakeholders, iterate.'),
        illustration: images.build_mockups,
        href: "/contact",
    },
    {
        label: 'CLIENT PORTAL ACCESS',
        title: t('Prototype'),
        description: t('Iteratively turn mock-ups into prototypes until ready for early adoption. Engage stakeholder and secure their commitment on the way, ensure commercial viability.'),
        illustration: images.build_proto,
        href: "/contact",
    },
];