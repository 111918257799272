import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, TextField, CircularProgress } from '@material-ui/core';
import validate from 'validate.js';


const useStyles = makeStyles(theme => ({
  form: {
    maxWidth: 550,
    margin: `0 auto`,
    '& .MuiTextField-root': {
      background: theme.palette.background.paper,
    },
    '& .MuiOutlinedInput-input': {
      background: theme.palette.background.paper,
    },
  },
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  wrapper: {
    position: 'relative',
  },
  sendProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));


const ContactForm = ({...props}) => {
  // Translation temporarily disabled
  //const {t} = useTranslation();
  const t = (txt)=>txt;
  const classes = useStyles();

  const schema = {
    fullname: {
      presence: { allowEmpty: false, message: t('is required') },
      length: {
        maximum: 128,
      },
    },
    email: {
      presence: { allowEmpty: false, message: t('is required') },
      email: true,
      length: {
        maximum: 300,
      },
    },
    message: {
      presence: { allowEmpty: false, message: t('is required') },
    },
  };

  const [formState, setFormState] = React.useState({
    isValid: false,
    isSending: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const zsubmit = event => {
    event.preventDefault();
    setFormState(formState => ({...formState,isSending: true}));
    getZammadConfig(props.url).then(config=>{
      zammadSubmit(config).then(result=>{
        console.debug(result);
        //useHistory().push("/result");
        window.location="/result";
      });
    })

  };

  // just provide a default
  const fingerprint=props.fingerprint || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgBBBSw';

  async function getZammadConfig(server){
    const response = await fetch(server+'/api/v1/form_config' ,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        fingerprint: fingerprint
      }),
    });

    return response.json();
  }

  async function zammadSubmit(config){
    if (!config.enabled){
      log.error("Server reports form Submission disabled! ")
    }
    const response = await fetch(config.endpoint,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: formState.values.fullname,
        email: formState.values.email,
        body: formState.values.message,
        title: props.ticketTitle,
        fingerprint: fingerprint,
        token: config.token
      })
    });
    return response.json();
  }

  return (
    <div className={classes.form}>
      <form
        name="contact-form"
        method="post"
        onSubmit={zsubmit}
      >
        <input type="hidden" name="form-name" value="contact-form" />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              placeholder={t("Full Name")}
              label={t("Full Name")+" *"}
              variant="outlined"
              size="medium"
              name="fullname"
              fullWidth
              helperText={
                hasError('fullname') ? formState.errors.fullname[0] : null
              }
              error={hasError('fullname')}
              onChange={handleChange}
              type="text"
              disabled={formState.isSending}
              value={formState.values.fullname || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder={t("Work E-mail")}
              label={t("E-mail")+" *"}
              variant="outlined"
              size="medium"
              name="email"
              fullWidth
              helperText={hasError('email') ? formState.errors.email[0] : null}
              error={hasError('email')}
              onChange={handleChange}
              type="email"
              disabled={formState.isSending}
              value={formState.values.email || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder={t("Message")}
              label={t("Message")+" *"}
              variant="outlined"
              name="message"
              fullWidth
              helperText={
                hasError('message') ? formState.errors.message[0] : null
              }
              error={hasError('message')}
              onChange={handleChange}
              multiline
              rows={4}
              disabled={formState.isSending}
              value={formState.values.message || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              {t("Fields that are marked with * sign are required.")}
            </Typography>
            <Button
              size="large"
              variant="contained"
              type="submit"
              color="primary"
              disabled={!formState.isValid || formState.isSending}
            >
              {formState.isSending? t("Sending...") : t("Send")}
            </Button>
          </Grid>
          {
            formState.isSending &&
              <Grid item xs={12}>
                <CircularProgress/>
              </Grid>
          }
        </Grid>
      </form>
    </div>
  );
};

export default ContactForm;
