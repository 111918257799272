import {images} from "../components/MediaLib";

//const {t} = useTranslation();
const t = (txt)=>txt;

export const OpenSourceStacks = [
    {
        logo: images.nodejs_stack,
        name: 'NodeJS',
    },
    {
        logo: images.gitlab_stack,
        name: 'Gitlab',
    },
    {
        logo: images.kubernetes_stack,
        name: 'Kubernetes',
    },

    {
        logo: images.ansible_stack,
        name: 'Ansible',
    },
    {
        logo: images.rust_stack,
        name: 'Rust',
    },
    {
        logo: images.substrate_stack,
        name: 'Substrate_',
    },

    {
        logo: images.docker_stack,
        name: 'Docker',
    },
    {
        logo: images.java_stack,
        name: 'Java',
    },
    {
        logo: images.cloudstack,
        name: 'Cloudstack',
    },


];