import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, colors } from '@material-ui/core';
import { SectionHeader, IconAlternate } from 'components/molecules';
import { CardBase, DescriptionListIcon } from 'components/organisms';

export const Process = ({process,className,...props}) => {

    return (
        <div className={className} data-aos="fade-up">
            <SectionHeader
                title={props.title}
                subtitle={props.subtitle}
                ctaGroup={[
                    <Button color="primary" variant="contained" size="large" href={props.href}>
                        {props.action}
                    </Button>,
                ]}
                fadeUp
            />
            <Grid container spacing={4}>
                {process.map((item, index) => (
                    <Grid
                        key={index}
                        item
                        container
                        alignItems="center"
                        direction="column"
                        xs={12}
                        sm={6}
                        data-aos="fade-up"
                    >
                        <CardBase liftUp variant="outlined">
                            <DescriptionListIcon
                                icon={
                                    <IconAlternate
                                        fontIconClass={item.icon}
                                        color={colors.indigo}
                                    />
                                }
                                title={item.title}
                                subtitle={item.description}
                                align="left"
                            />
                        </CardBase>
                    </Grid>
                ))}
                <Grid item container xs={12} justifyContent="center">
                    <Button variant="contained" size="large" color="primary" href={props.href}>
                        {props.action}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

Process.propTypes = {
    /**
     * External classes
     */
    className: PropTypes.string,
    /**
     * data to be rendered
     */
    process: PropTypes.array.isRequired,
};

