
//const {t} = useTranslation();
const t = (txt)=>txt;

export const PeopleInTechProcess = [
    {
        icon: 'fas fa-handshake',
        title: t('Business Partnering'),
        description: t('We ensure your Business Leaders understand People and your People Leaders understand your Business.'),
    },
    {
        icon: 'fas fa-puzzle-piece',
        title: t('Mergers and Acquisitions'),
        description: t('We help you integrate your newly acquired business.'),
    },
    {
        icon: 'fas fa-seedling',
        title: t('Coaching and Mentoring'),
        description: t('We coach your Tech Leaders into People Leaders.'),
    },
    {
        icon: 'fas fa-expand-arrows-alt',
        title: t('DAOs'),
        description: t('We help you decentralize your team. We help Decentralized Organisations maximise their human potential.'),
    },
];