import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  label: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
}));

export const Result = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Section className={classes.section}>
        <div className={classes.formContainer}>
          <Box display="flex" justifyContent="space-between" alignItems="center" padding={2} border="0" >
            <Image src={require("assets/images/done.svg")} />
          </Box>
          <SectionHeader          
            subtitle={props.message}
            titleProps={{
              variant: 'h3',
            }}
            ctaGroup={[
              <Button
                size="large"
                variant="contained"
                color="primary"
                href={props.back_href}
              >
                {props.back_message}
              </Button>,
            ]}
            disableGutter
          />
        </div>
      </Section>
    </div>
  );
};
