
//const {t} = useTranslation();
const t = (txt)=>txt;

export const Services = [
  {
    icon: 'fas fa-lightbulb',
    title: t('Innovation'),
    description: t('We help you bring new products, services or startup venture to life, minimizing time and cost to arrive to your Minimum Viable Product.'),
  },
  {
    icon: 'fas fa-laptop-code',
    title: t('Technical Leadership'),
    description: t('We help you developing your new product with hands-on, qualified Technical Leadership that can engage business stakeholders and achieve commercial viability.'),
  },
  {
    icon: 'fas fa-users',
    title: t('People in Tech'),
    description: t('We help you build and integrate great teams with hands-on, qualified People Leadership that understand your technology business.'),
  }
];
