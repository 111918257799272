import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    useMediaQuery,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemIcon,
    Avatar, Grid,
} from '@material-ui/core';

import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
    list: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },
    listItemText: {
        display: 'flex',
        flexDirection: 'column',
        flex: '0 0 auto',
    },
    listItem: {
        justifyContent: 'flex-start',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
        },
    },
    icon: {
        background: 'transparent',
        borderRadius: 0,
    },
}));

export const ContactDetails = ({themeMode,className,details, ...props}) => {
    const classes = useStyles();

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (
        <div className={className}>
            <SectionHeader
                title={props.title}
                subtitle={props.subtitle}
                data-aos="fade-up"
                align={isMd ? 'center' : 'left'}
            />
            <List disablePadding className={classes.list}>
                {details.map((item, index) => (
                    <ListItem
                        key={`c-d-${index}`}
                        disableGutters
                        data-aos="fade-up"
                        className={classes.listItem}
                    >
                        <ListItemAvatar>
                            <Avatar
                                src={item.icon}
                                srcSet={item.icon2 || item.icon}
                                className={classes.icon}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            className={classes.listItemText}
                            primary={item.title}
                            secondary={item.detail}
                            primaryTypographyProps={{
                                variant: 'subtitle1',
                                color: 'textSecondary',
                            }}
                            secondaryTypographyProps={{
                                variant: 'subtitle1',
                                color: 'textPrimary',
                                component: 'span',
                            }}
                        />

                    </ListItem>
                ))}
            </List>
        </div>
    );
};

ContactDetails.propTypes = {
    /**
     * External classes
     */
    className: PropTypes.string,
    details: PropTypes.array.isRequired
};
