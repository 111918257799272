import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Image } from 'components/atoms';

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.alternate.main,
        width: '100%',
    },
    noBg: {
        background: 'transparent',
    },
    copy: {
        textAlign: 'center',
        maxWidth: 700,
        margin: '0 auto',
        padding: theme.spacing(2, 2),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(4, 2),
        },
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    logoImg: {
        maxWidth: 100,
    },
    fontWeight700: {
        fontWeight: 700,
    },
}));

export const References = ({ references, className, ...props }) => {
    const classes = useStyles();

    // Translation temporarily disabled
    //const {t} = useTranslation();
    const t = (txt)=>txt;

    return (
        <div className={clsx(classes.root, className)} data-aos="fade-up">
            <div className={classes.copy}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            color="textSecondary"
                            align="center"
                            className={classes.fontWeight700}
                        >
                            {t(props.title)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between">
                            {references.map((partner, index) => (
                                <Grid item xs={6} sm={2} key={index}>
                                    <Image
                                        src={partner.logo}
                                        alt={partner.name}
                                        className={classes.logoImg}
                                        lazy={false}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

References.propTypes = {
    /**
     * External classes
     */
    className: PropTypes.string,
    /**
     * data to be rendered
     */
    references: PropTypes.array.isRequired,
};

