import {images} from "../components/MediaLib";


export const References = [
    {
        name: 'Tibco',
        logo: images.tibco,
    },
    {
        name: 'Reuters',
        logo: images.reuters,
    },
    {
        name: 'Credit Suisse',
        logo: images.credit_swisse,
    },
    {
        name: 'Vodafone',
        logo: images.vodafone,
    },
    {
        name: 'Celpax',
        logo: images.celpax,
    },
];
