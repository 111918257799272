
//const {t} = useTranslation();
const t = (txt)=>txt;

export const TechleadProcess = [
    {
        icon: 'fas fa-globe',
        title: t('Web 3'),
        description: t('Decentralize your computation, join the Web 3.0.'),
    },
    {
        icon: 'fas fa-mobile-alt',
        title: t('Decentralized Applications'),
        description: t('Fault tolerance/resilience are terms of the past. Create distributed, unstoppable applications.'),
    },
    {
        icon: 'fas fa-server',
        title: t('Infrastructure as code'),
        description: t('Automate software defined infrastructure.'),
    },
    {
        icon: 'fas fa-microchip',
        title: t('Internet of Things'),
        description: t('Ingest, understand and react to physical events.'),
    },
];