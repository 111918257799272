import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {} from '@material-ui/core';
import { useMediaQuery, Grid, Button } from '@material-ui/core';
import { Image, LearnMoreLink } from 'components/atoms';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
    listGrid: {
        overflow: 'hidden',
        marginBottom: theme.spacing(3),
        '&:last-child': {
            marginBottom: theme.spacing(0),
        },
    },
}));

export const WorkFlow = ({themeMode,className, workflow,...props}) => {
    const classes = useStyles();

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (
        <div className={className} data-aos="fade-up">
            <SectionHeader
                title={props.title}
                subtitle={props.subtitle}
            />
            <Grid container justifyContent="center">
                {workflow.map((item, index) => (
                    <Grid
                        data-aos="fade-up"
                        key={index}
                        item
                        container
                        xs={12}
                        spacing={isMd ? 4 : 2}
                        direction={index % 2 === 1 ? 'row-reverse' : 'row'}
                        className={classes.listGrid}
                    >
                        <Grid item xs={12} sm={6}>
                            <SectionHeader
                                titleVariant="h5"
                                title={item.title}
                                subtitle={item.description}
                                ctaGroup={[<LearnMoreLink title={props.action_link} variant="h6" href={item.href}/>]}
                                align="left"
                                disableGutter
                            />
                        </Grid>
                        <Grid item container justifyContent="center" xs={12} sm={6}>
                            <Image src={item.illustration} alt={item.title} />
                        </Grid>
                    </Grid>
                ))}
                <Grid item container justifyContent="center" xs={12}>
                    <Button variant="contained" color="primary" href={props.href}>
                        {props.action}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

WorkFlow.propTypes = {
    /**
     * External classes
     */
    className: PropTypes.string,
    /**
     * data to be rendered
     */
    workflow: PropTypes.array.isRequired,
};

